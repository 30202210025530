.bootstrap-wrap {
  /* --- Overrite bootstrap ---*/
  /* >>> GENERAL <<<*/
  /* >>> COMPONENTS <<<*/
  /*Question*/
  /*QuestionsPage*/
  /*Welcome Page*/
  /*Summary table*/
  /*Custom Form*/
  /* Radio */
  /* This is the default state */
  /* This is the checked state */
  /* This is the checked state */
  /* active state i.e. displayed while the mouse is being pressed down */
  /* Result Overview*/
  /*Result Overview mobile */
  /* loader */
  /* Results */
  /* select */
  /* Datepicker */
  /* Carousel */
}

:root {
  --background-color: #FFFFFF;
  --highlight-color-1: #000000;
  --highlight-color-2: #BDBDBD;
  --accent-color-1: #E5E5E5;
  --accent-color-2: #7F7F7F;
  --accent-color-3: #EBEBEB;
  --font-color: #000;
  --font-size-big: 24px;
  --font-size-medium: 18px;
  --font-size-small: 16px;
}

@media screen and (max-width: 765px) {
  .bootstrap-wrap :root {
    --font-size-small: 10px;
  }
}

@media screen and (max-width: 500px) {
  .bootstrap-wrap :root {
    --font-size-small: 7px;
  }
}

.bootstrap-wrap .footer {
  font-size: var(--font-size-small);
  background: var(--background-color);
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-top: auto;
}

.bootstrap-wrap .footer a {
  margin: 0 6px;
}

.bootstrap-wrap legend {
  font-size: var(--font-size-small);
  font-weight: normal;
}

.bootstrap-wrap .form-group {
  padding: 0 10px;
}

.bootstrap-wrap .form-group .row {
  min-height: 84px;
  margin: 16px 0;
}

.bootstrap-wrap .form-control,
.bootstrap-wrap .react-datepicker__input-container,
.bootstrap-wrap button {
  color: var(--highlight-color-1);
  background-color: var(--background-color);
  border-color: var(--highlight-color-1);
  margin-bottom: 15px;
  border-radius: 1px;
}

.bootstrap-wrap button {
  color: var(--highlight-color-1);
  border-color: var(--highlight-color-1);
  padding: 8px 20px;
  min-height: 50px;
}

.bootstrap-wrap button:disabled,
.bootstrap-wrap button:disabled:hover {
  color: var(--accent-color-1);
  border: 1px solid var(--accent-color-1);
  background-color: var(--background-color);
}

.bootstrap-wrap button:focus {
  outline: none;
}

.bootstrap-wrap button:hover {
  border-color: transparent;
  background-color: var(--accent-color-2);
}

.bootstrap-wrap .form-control:focus {
  border-color: var(--accent-color-1);
  box-shadow: none;
}

.bootstrap-wrap select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50% !important;
  background-repeat: no-repeat !important;
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path stroke="black" fill="black" d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path d="M0 0h24v24H0V0z" fill="none"/></svg>') !important;
}

.bootstrap-wrap .icon-button {
  padding: 8px 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  color: var(--highlight-color-1);
  background-color: var(--background-color);
}

.bootstrap-wrap .icon-button:first-of-type {
  margin-left: 0;
}

.bootstrap-wrap .icon-button:last-of-type {
  margin: 0;
}

.bootstrap-wrap .icon-button.notselected {
  color: var(--accent-color-2);
  border-color: var(--accent-color-2);
}

.bootstrap-wrap .icon-button.notselected:hover {
  color: var(--highlight-color-1);
}

.bootstrap-wrap .icon-button.notselected svg * {
  stroke: var(--accent-color-2);
}

.bootstrap-wrap .icon-button.notselected:hover svg * {
  stroke: var(--highlight-color-1);
}

.bootstrap-wrap .icon-button svg {
  margin-right: 10px;
}

.bootstrap-wrap .icon-button svg * {
  stroke: var(--highlight-color-1);
}

.bootstrap-wrap .progress {
  margin-top: 10px;
  border-radius: 1px;
  background-color: var(--accent-color-1);
}

.bootstrap-wrap .progressMobile {
  margin-top: 10px;
  border-radius: 1px;
}

.bootstrap-wrap .progress-bar {
  transition: opacity 1s;
  background-color: var(--accent-color-2);
}

.bootstrap-wrap .progress-bar-active {
  background-color: var(--highlight-color-1);
}

.bootstrap-wrap .progress-bar:hover {
  background-color: var(--highlight-color-2);
}

.bootstrap-wrap .custom-tooltip {
  background-color: var(--background-color);
  color: var(--font-color);
  opacity: 1;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  padding: 16px;
  border: 2px solid var(--highlight-color-2);
  border-radius: 4px;
}

.bootstrap-wrap .custom-tooltip-dark {
  background-color: var(--highlight-color-2);
  color: var(--background-color);
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 15px;
  border-radius: 0px;
}

.bootstrap-wrap .tooltip .arrow:before {
  border-top-color: var(--highlight-color-2);
  border-bottom-color: var(--highlight-color-2);
}

.bootstrap-wrap .custom-tooltip-arrow {
  stroke: var(--highlight-color-2);
}

.bootstrap-wrap .button {
  background-color: var(--background-color);
  border: 1px solid var(--highlight-color-1);
  color: var(--font-color);
  min-height: 50px;
  font-size: var(--font-size-medium);
  font-weight: normal;
  padding-top: 4px;
}

.bootstrap-wrap .buttonHighlight {
  background-color: var(--highlight-color-1);
  border: 1px solid var(--highlight-color-1);
  color: var(--background-color);
  min-height: 50px;
  font-size: var(--font-size-medium);
  font-weight: normal;
}

.bootstrap-wrap .buttonHighlightDarkMode {
  background-color: var(--highlight-color-1);
  border: 1px solid var(--highlight-color-1);
  color: var(--background-color);
  min-height: 50px;
  font-size: var(--font-size-medium);
  font-weight: normal;
}

.bootstrap-wrap .button:hover {
  color: var(--highlight-color-1);
  background: var(--accent-color-2);
}

@font-face {
  font-family: 'AkkoRoundedThin';
  src: url('static/fonts/rounded_thin.ttf');
}

@font-face {
  font-family: 'AkkoRoundedRegular';
  src: url('static/fonts/AkkoRoundedW02-Regular.woff');
}

body {
  background: var(--background-color);
  /*font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;*/
  font-family: 'AkkoRoundedThin';
  color: var(--font-color);
  font-size: var(--font-size-small);
}

.bootstrap-wrap a {
  color: var(--highlight-color-1);
}

.bootstrap-wrap a:hover {
  color: var(--highlight-color-3);
  text-decoration: none;
}

.bootstrap-wrap ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--font-color);
  opacity: 1;
  /* Firefox */
}

.bootstrap-wrap :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--font-color);
}

.bootstrap-wrap ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--font-color);
}

.bootstrap-wrap .loader {
  text-align: center;
  color: var(--highlight-color-1);
}

.bootstrap-wrap .question:hover .description {
  background-color: var(--font-color);
}

.bootstrap-wrap .description {
  font-size: var(--font-size-small);
  font-weight: 500;
  line-height: normal;
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
}

.bootstrap-wrap .mobile .description {
  font-size: 12px;
}

.bootstrap-wrap .questionSelected .description {
  color: var(--background-color);
  background-color: var(--font-color);
}

.bootstrap-wrap .title {
  font-size: var(--font-size-big);
  font-family: 'AkkoRoundedRegular';
}

.bootstrap-wrap .flexCenter {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media screen and (max-width: 999px) {
  .bootstrap-wrap .flexCenter {
    justify-content: center;
  }
}

.bootstrap-wrap .flexCenterOne {
  display: flex;
  justify-content: center;
}

.bootstrap-wrap .pointer {
  cursor: pointer;
}

.bootstrap-wrap .container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  /*min-height: 100vh;*/
}

@media screen and (max-width: 999px) {
  .bootstrap-wrap .container {
    padding-top: 0;
  }
}

.bootstrap-wrap .sm-view.container {
  padding-top: 10px;
}

.bootstrap-wrap .progressBarContainer {
  min-height: 64px;
  width: 100%;
}

.bootstrap-wrap .arrowContainer {
  display: flex;
  justify-content: space-between;
}

.bootstrap-wrap .arrowContainerContent > svg * {
  stroke: var(--highlight-color-1);
}

.bootstrap-wrap .arrowContainerContent:hover > svg * {
  stroke: var(--highlight-color-2);
}

.bootstrap-wrap .arrowContainerContent:hover {
  color: var(--highlight-color-2);
}

.bootstrap-wrap .arrowContainerContent {
  display: flex;
  align-items: center;
  width: 80px;
  justify-content: space-between;
  user-select: none;
}

.bootstrap-wrap .sm-view .arrowContainerContent {
  margin: 0 10px;
}

.bootstrap-wrap .arrowContainer .fa-arrow-left {
  margin-right: 10px;
}

.bootstrap-wrap .arrowContainer .fa-arrow-right {
  margin-left: 10px;
}

.bootstrap-wrap .paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
  height: 32px;
  position: absolute;
  top: 120px;
  width: 100%;
  z-index: 1;
}

.bootstrap-wrap .paginationNumbering {
  font-weight: bold;
  color: var(--font-color);
}

.bootstrap-wrap .pagination {
  display: inline-block;
  font-size: 10px;
  text-align: center;
  border: 1px solid var(--highlight-color-1);
  border-radius: 20px;
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-left: 6px;
}

.bootstrap-wrap .disabledNext {
  color: var(--accent-color-1);
}

.bootstrap-wrap .disabledNext > svg * {
  stroke: var(--accent-color-1);
}

.bootstrap-wrap .paper {
  width: 230px;
  height: 230px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.bootstrap-wrap .paper.question-mobile {
  width: 115px;
  height: 115px;
}

.bootstrap-wrap .sm-view .paper {
  /*margin: 40px;*/
}

.bootstrap-wrap .paper > svg {
  margin-bottom: 20px;
}

.bootstrap-wrap .paper > svg * {
  /*stroke: var(--highlight-color-1);*/
  stroke: var(--background-color);
}

.bootstrap-wrap .paper:hover {
  border-color: var(--highlight-color-2);
}

.bootstrap-wrap .paper:hover > svg * {
  stroke: var(--highlight-color-2);
}

.bootstrap-wrap .paper .description {
  font-weight: bold;
}

.bootstrap-wrap .paper:hover .description {
  color: var(--highlight-color-2);
}

.bootstrap-wrap .multiple-description {
  font-size: var(--font-size-medium);
  margin-top: 60px;
}

.bootstrap-wrap .sm-view .multiple-description {
  margin: 6px 0;
}

.bootstrap-wrap .question {
  padding: 0;
  border-radius: 0;
  /*padding-bottom: 20px;*/
  background-color: var(--accent-color-1);
}

.bootstrap-wrap .question:hover {
  /*background-color: var(--primary-color);*/
}

.bootstrap-wrap .question:hover .description {
  color: var(--background-color);
  background-color: var(--font-color);
}

.bootstrap-wrap .question-image {
  width: 100%;
  height: 75%;
  object-fit: cover;
}

.bootstrap-wrap .question .question-svg-container {
  height: 75%;
  width: 100%;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--highlight-color-1);
}

.bootstrap-wrap .question svg g * {
  stroke: var(--background-color);
}

.bootstrap-wrap .question path {
  stroke: var(--background-color) !important;
}

.bootstrap-wrap .questionSelected > svg * {
  stroke: var(--highlight-color-2);
}

.bootstrap-wrap .questionSelected > img,
.bootstrap-wrap .questionSelected > .question-svg-container {
  filter: brightness(0.5);
}

.bootstrap-wrap .questionSelected {
  /*color: var(--highlight-color-2);*/
  border-color: var(--highlight-color-2);
}

.bootstrap-wrap .paper-container {
  display: flex;
  align-items: center;
  justify-content: center;
  /*width: 230px;*/
}

.bootstrap-wrap .paper-container.mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 115px;
  width: 115px;
  margin: 15px;
}

.bootstrap-wrap .selected-indicator {
  background: var(--highlight-color-1);
  width: 60px;
  border-radius: 50%;
  height: 60px;
  bottom: 48%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 2;
}

.bootstrap-wrap .paper-container.mobile .selected-indicator {
  height: 30px;
  width: 30px;
  bottom: auto;
}

.bootstrap-wrap .selected-indicator .indicator-icon {
  background-color: var(--background-color);
  -webkit-mask-image: url('static/gc_icons/checkmark.svg');
  mask-image: url('static/gc_icons/checkmark.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.bootstrap-wrap .page {
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: all 1s ease;
}

.bootstrap-wrap .pageHeader {
  margin: 0 0 100px 0;
  width: 100%;
  text-align: center;
  height: 120px;
}

.bootstrap-wrap .sm-view .pageHeader {
  margin: 0 0 140px 0;
}

.bootstrap-wrap .infoBoxContainer {
  display: flex;
  justify-content: center;
}

.bootstrap-wrap .infoBox {
  position: relative;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 10px;
  color: var(--font-color);
}

.bootstrap-wrap .infoBox svg {
  margin-right: 10px;
}

.bootstrap-wrap .popup {
  display: none;
  position: absolute;
  top: 24px;
  z-index: 5;
  width: 280px;
  padding: 8px 20px;
  background-color: var(--accent-color-1);
  color: var(--highlight-color-1);
  border-radius: 1px;
}

.bootstrap-wrap .infoBox:hover > .popup {
  display: block;
}

.bootstrap-wrap .welcome {
  margin-top: 10px;
}

.bootstrap-wrap .welcome-content {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 80px;
  margin-bottom: 60px;
}

.bootstrap-wrap .welcome-content svg * {
  stroke: var(--highlight-color-1);
}

.bootstrap-wrap .sm-view .welcome-content {
  display: block;
}

.bootstrap-wrap .sm-view .welcome-content-item {
  display: flex;
  text-align: start;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.bootstrap-wrap .sm-view .welcome-content-item:first-of-type {
  margin-top: 20px;
}

.bootstrap-wrap .welcome-headline {
  font-weight: bold;
  margin-top: 60px;
  margin-bottom: 20px;
}

.bootstrap-wrap .sm-view .welcome-headline {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px;
}

.bootstrap-wrap .sm-view .welcome-content-item-content {
  margin-left: 20px;
}

.bootstrap-wrap .separator {
  height: 3px;
  margin: 0 -30px;
  width: 300px;
  background: var(--highlight-color-1);
}

.bootstrap-wrap .summaryTable {
  position: relative;
  text-align: center;
  background: var(--background-color);
  padding: 10px 20px;
}

.bootstrap-wrap .summaryTableContent {
  display: flex;
  flex-wrap: wrap;
}

.bootstrap-wrap .summaryTableRow {
  display: flex;
  justify-content: space-between;
}

.bootstrap-wrap .summaryTableSum {
  text-align: right;
  font-size: 20px;
}

.bootstrap-wrap .form-buttons {
  margin-top: 40px;
}

.bootstrap-wrap .form-buttons button {
  margin: 0 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.bootstrap-wrap .sm-view .form-buttons button {
  margin: 5px 0;
}

.bootstrap-wrap .contact {
  display: flex;
  flex-flow: column;
  flex: 1;
  align-items: center;
  padding: 10px;
}

.bootstrap-wrap .content {
  min-width: 364px;
  display: flex;
}

.bootstrap-wrap .content .header {
  font-size: 1rem;
  font-weight: bold;
}

.bootstrap-wrap .content .description {
  padding-top: 6px;
  padding-bottom: 12px;
  font-size: 1rem;
}

.bootstrap-wrap .content .avatar {
  width: 80px;
  height: 80px;
  border-radius: 120px;
  margin-right: 20px;
}

.bootstrap-wrap .custom-radio {
  display: flex;
  align-items: center;
}

.bootstrap-wrap .custom-radio .custom-control-input {
  height: 25px;
  width: 25px;
}

.bootstrap-wrap .custom-radio .custom-control-label {
  padding-left: 15px;
}

.bootstrap-wrap .custom-radio .custom-control-label::before,
.bootstrap-wrap .custom-radio .custom-control-label::after {
  background-color: var(--background-color);
  height: 25px;
  width: 25px;
  margin-top: -6px;
  border: 1px solid var(--highlight-color-2);
  border-radius: 50%;
}

.bootstrap-wrap .custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.bootstrap-wrap .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--background-color);
  /* green */
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  /*background-image: url(data:image/svg+xml;charset=utf8,<svg xmlns= 'http://www.w3.org/2000/svg' viewBox= '-4 -4 8 8' <circle r= '3' fill= '#fff' /></svg>);*/
  /*background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg height="100" width="100"> <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /> </svg>') !important;*/
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg"> <circle cx="60" cy="60" r="50"/> </svg>') !important;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-top: -6px;
}

.bootstrap-wrap .dark.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.bootstrap-wrap .dark.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--font-color);
  /* green */
  /* this bg image SVG is just a white circle, you can replace it with any valid SVG code */
  /*background-image: url(data:image/svg+xml;charset=utf8,<svg xmlns= 'http://www.w3.org/2000/svg' viewBox= '-4 -4 8 8' <circle r= '3' fill= '#fff' /></svg>);*/
  /*background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg height="100" width="100"> <circle cx="50" cy="50" r="40" stroke="black" stroke-width="3" fill="red" /> </svg>') !important;*/
  background-image: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="utf-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg"> <circle cx="60" cy="60" r="50"/> </svg>') !important;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  margin-top: -6px;
}

.bootstrap-wrap .custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: var(--background-color);
  background-color: var(--background-color);
  /* red */
}

.bootstrap-wrap .custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.bootstrap-wrap .custom-checkbox-results {
  margin-left: 25px;
  margin-bottom: 0px;
  margin-right: 0px;
  display: flex;
  flex: 100%;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  z-index: 10;
}

.bootstrap-wrap .custom-checkbox .custom-control-label {
  padding-left: 10px;
  cursor: pointer;
}

.bootstrap-wrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.bootstrap-wrap .custom-checkbox .custom-control-input ~ .custom-control-label::before {
  color: transparent;
  border-radius: 0;
  border-color: var(--highlight-color-1);
  background: transparent;
  height: 22px;
  width: 22px;
  margin-top: -5px;
  cursor: pointer;
}

.bootstrap-wrap .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--highlight-color-1);
  -webkit-mask-image: url('static/gc_icons/checkmark.svg');
  mask-image: url('static/gc_icons/checkmark.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  -webkit-mask-repeat: no-repeat;
  height: 22px;
  width: 22px;
  margin-top: -5px;
  cursor: pointer;
  background-image: none;
}

.bootstrap-wrap .custom-checkbox .custom-control-label::after {
  height: 22px;
  width: 22px;
  border-color: var(--accent-color-2);
  margin-top: -5px;
  cursor: pointer;
}

.bootstrap-wrap .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  cursor: pointer;
}

.bootstrap-wrap .custom-control-input:focus {
  border-color: var(--accent-color-2);
  cursor: pointer;
}

.bootstrap-wrap label.custom-control-label:hover::before {
  border: 1px solid #BDBDBD !important;
}

.bootstrap-wrap .resultOverviewHeader {
  box-sizing: border-box;
  height: 71px;
  width: 100%;
  border: 1px solid var(--accent-color-1);
  padding-left: 17.02px;
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.bootstrap-wrap .resultOverviewContainer {
  width: 100%;
  /*max-width: 83.333333%;*/
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 28px;
}

.bootstrap-wrap .resultOverviewExpand {
  border: 1px solid var(--accent-color-1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 48px;
}

.bootstrap-wrap .resultOverviewExpandSection {
  flex: 1 0 30%;
  padding-left: 31px;
  padding-right: 30px;
}

.bootstrap-wrap .resultOverviewHeaderItem {
  margin-right: 10px;
  align-items: center;
}

.bootstrap-wrap .resultOverviewHeaderText {
  color: var(--font-color);
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 29px;
  margin-top: 8px;
  margin-left: 5px;
}

.bootstrap-wrap .resultOverviewHeaderItemIcon {
  /*margin-bottom: 11px;*/
  /*margin-top: 12px;*/
  margin-right: 5px;
}

.bootstrap-wrap .resultOverviewHeaderItemIcon * {
  stroke: var(--font-color);
}

.bootstrap-wrap .resultOverviewHeaderItemText {
  /*margin-bottom: 10px;*/
  margin-top: 6px;
  display: inline-block;
  color: var(--font-color);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}

.bootstrap-wrap .toggleButton {
  margin-left: auto;
  box-sizing: border-box;
  min-height: 35px !important;
  width: 187.23px;
  border: 1px solid #EBEBEB;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 0px;
}

.bootstrap-wrap .editButton {
  margin-left: auto;
  box-sizing: border-box;
  min-height: 35px;
  width: 187.23px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--accent-color-1);
}

.bootstrap-wrap .editButtonIcon {
  margin-top: 17px;
  margin-bottom: 13px;
  margin-right: 5px;
}

.bootstrap-wrap .editButtonIcon * {
  stroke: var(--font-color);
}

.bootstrap-wrap .editButtonText {
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--highlight-color-1);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 10px;
}

.bootstrap-wrap .resultOverviewPageItem {
  flex: 1 0 30%;
  margin-top: 7px;
}

.bootstrap-wrap .resultOverviewCheckboxContainer {
  display: flex;
  flex-wrap: wrap;
}

.bootstrap-wrap .resultOverviewCheckboxItem {
  min-width: 100%;
  margin-bottom: 10px;
}

.bootstrap-wrap .borderRight {
  border: 1px solid var(--accent-color-1);
  border-bottom: 0px;
  border-left: 0px;
  border-top: 0px;
}

.bootstrap-wrap .resultOverviewContainerMobile {
  width: 100%;
  margin-bottom: 17px;
}

.bootstrap-wrap .resultOverviewHeaderMobile {
  display: flex;
  align-items: center;
  background-color: var(--accent-color-3);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 19px;
  padding-left: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -10px;
}

.bootstrap-wrap .resultOverviewHeaderMobile.darkMode {
  background-color: var(--primary-color);
}

.bootstrap-wrap .resultOverviewHeaderMobileExpanded {
  display: flex;
  align-items: center;
  background-color: var(--highlight-color-1);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 19px;
  padding-left: 15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -10px;
  margin-bottom: -17px;
}

.bootstrap-wrap .resultOverviewHeaderMobileExpanded g {
  stroke: white !important;
}

.bootstrap-wrap .resultOverviewHeaderTextMobile {
  color: var(--background-color);
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 29px;
  margin-left: 5px;
}

.bootstrap-wrap .resultOverviewHeaderItemMobile {
  margin-left: 10px;
}

.bootstrap-wrap .resultOverviewExpandSectionMobile {
  flex: 1 0 30%;
}

.bootstrap-wrap .resultOverviewExpandMobile {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-bottom: 48px;
  width: 100%;
}

.bootstrap-wrap .toggleButtonMobile {
  background: none;
  color: white;
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  min-height: 35px !important;
  border: 1px solid #EBEBEB;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 8px;
  margin-top: 7px;
}

.bootstrap-wrap .resultOverviewButtonContainerMobile {
  background-color: black;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 19px;
  padding-left: 15px;
  width: 100vw;
  position: relative;
  left: 0px;
  margin-bottom: 20px;
}

.bootstrap-wrap .editButtonIconMobile {
  margin-top: 12px;
  margin-left: 5px;
  margin-bottom: 13px;
  margin-right: 3px;
}

.bootstrap-wrap .editButtonIconMobile.darkMode g {
  stroke: var(--highlight-color-1);
}

.bootstrap-wrap .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.bootstrap-wrap .double-bounce1,
.bootstrap-wrap .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.bootstrap-wrap .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.bootstrap-wrap .title-menu-item {
  color: var(--accent-color-2);
  margin-right: 10px;
  cursor: pointer;
}

.bootstrap-wrap .title-menu-item.selected {
  color: var(--highlight-color-1);
}

.bootstrap-wrap .results .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.bootstrap-wrap .sm-view .results .top {
  display: block;
}

.bootstrap-wrap .sm-view .results .top .title {
  margin-bottom: 10px;
}

.bootstrap-wrap .results-content {
  padding: 0;
}

.bootstrap-wrap .results-item {
  min-height: 500px;
  margin-bottom: 20px;
}

.bootstrap-wrap .results-item-footer {
  display: flex;
  flex-direction: column;
}

.bootstrap-wrap .results-item .results-item-container {
  background: var(--background-color);
}

.bootstrap-wrap .results-item .results-item-container-dark {
  background: var(--accent-color-1);
}

.bootstrap-wrap .results-item .card {
  background: transparent;
  border: 0;
}

.bootstrap-wrap .results-item .card-body {
  padding: 10px 0 30px 0;
}

.bootstrap-wrap .results-item .card-header {
  border-bottom: 0;
  border-top: 1px solid var(--accent-color-2);
  border-radius: 0;
  background: transparent;
  font-size: var(--font-size-medium);
  padding: 10px 0;
}

.bootstrap-wrap .results-item .card-header a {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
}

.bootstrap-wrap .results-item .card-header a i {
  background: var(--highlight-color-1);
  color: var(--background-color);
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.bootstrap-wrap .results-item .card-header a .fa-chevron-up {
  display: flex;
}

.bootstrap-wrap .results-item .card-header a .fa-chevron-down {
  display: none;
  padding-top: 4px;
}

.bootstrap-wrap .results-item .card-header a.collapsed .fa-chevron-down {
  display: flex;
}

.bootstrap-wrap .results-item .card-header a.collapsed .fa-chevron-up {
  display: none;
}

.bootstrap-wrap .results-item .card-body {
  padding-top: 0;
}

.bootstrap-wrap .results-item .card-body ul {
  padding: 0;
  margin: 0 0 0 20px;
}

.bootstrap-wrap .results-item li {
  list-style-image: url('static/gc_icons/checkmark.svg');
}

.bootstrap-wrap .results-item li.dark {
  list-style-image: url('static/gc_icons/checkmark_light.svg');
}

.bootstrap-wrap .results-item .results-item-head {
  max-height: 203px;
  background: var(--accent-color-2);
  background-repeat: no-repeat;
  background-size: cover;
  height: 30vh;
  min-height: 120px;
}

.bootstrap-wrap .results-item .results-item-content {
  padding: 10px;
  text-align: start;
}

.bootstrap-wrap .results-item .results-item-info {
  font-size: var(--font-size-big);
  margin-bottom: 25px;
}

.bootstrap-wrap .results-item .results-item-info .results-item-info-label {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.bootstrap-wrap .results-item .card-header-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bootstrap-wrap .results-item .results-item-info img {
  height: 16px;
  width: auto !important;
}

.bootstrap-wrap .results-item .results-item-head-badge {
  position: absolute;
  background: var(--highlight-color-1);
  color: var(--background-color);
  padding: 4px 8px;
  left: 24px;
  top: 10px;
  height: 26px;
}

.bootstrap-wrap .results-item .results-item-head-badge-dark {
  position: absolute;
  background: var(--background-color);
  color: var(--highlight-color-1);
  padding: 4px 8px;
  left: 24px;
  top: 10px;
  height: 26px;
}

.bootstrap-wrap .results-item .results-item-badge .results-item-head-badge {
  position: relative;
  margin-left: 0;
  text-transform: uppercase;
}

.bootstrap-wrap .wishlist-form-headline {
  margin: 10px 0 20px 0;
}

.bootstrap-wrap .select-container .control {
  border: 1px solid var(--accent-color-3);
  border-radius: 0;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
}

.bootstrap-wrap .select-container ul {
  border: 1px solid var(--accent-color-2);
  border-radius: 0;
  padding: 5px 15px;
}

.bootstrap-wrap .select-container ul a {
  font-weight: normal;
}

.bootstrap-wrap .select-container ul a:hover {
  font-weight: bold;
  background: transparent;
}

.bootstrap-wrap .select-container:hover i {
  color: var(--highlight-color-2);
}

.bootstrap-wrap .react-datepicker-wrapper,
.bootstrap-wrap .react-datepicker-wrapper input {
  width: 100%;
}

.bootstrap-wrap .react-datepicker {
  border-radius: 2px;
}

.bootstrap-wrap .react-datepicker__input-container input {
  font-size: 1rem;
  height: 36px;
  border: 1px solid var(--highlight-color-1);
  padding-left: 10px;
  background: var(--background-color);
  color: var(--highlight-color-1);
}

.bootstrap-wrap .react-datepicker__input-container input:focus {
  outline: none;
}

.bootstrap-wrap .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: var(--background-color);
}

.bootstrap-wrap .react-datepicker__header {
  background-color: var(--background-color);
}

.bootstrap-wrap .react-datepicker__current-month,
.bootstrap-wrap .react-datepicker__day-name {
  color: var(--highlight-color-1);
}

.bootstrap-wrap .react-datepicker__month,
.bootstrap-wrap .react-datepicker__month-container {
  background-color: var(--background-color);
}

.bootstrap-wrap .react-datepicker__day {
  background: var(--background-color);
  color: var(--highlight-color-1);
  border: 1px solid var(--accent-color-3);
  border-radius: 2px;
}

.bootstrap-wrap .react-datepicker__day--disabled {
  background: var(--accent-color-3);
  color: var(--background-color);
  border: none;
}

.bootstrap-wrap .react-datepicker__day--selected {
  background: var(--highlight-color-1);
  color: var(--background-color);
}

.bootstrap-wrap .react-datepicker__day:hover {
  background: var(--accent-color-2);
  color: var(--background-color);
  border-radius: 2px;
}

.bootstrap-wrap .react-datepicker__navigation {
  min-height: auto;
}

.bootstrap-wrap .react-datepicker__navigation:first-of-type {
  border-right-color: var(--accent-color-2);
  border-left-color: transparent;
}

.bootstrap-wrap .react-datepicker__navigation:last-of-type {
  border-right-color: transparent;
  border-left-color: var(--accent-color-2);
}

.bootstrap-wrap .react-datepicker__navigation:hover {
  background: none;
}

.bootstrap-wrap .react-datepicker__navigation:first-of-type:hover {
  border-right-color: var(--highlight-color-1);
  border-left-color: transparent;
}

.bootstrap-wrap .react-datepicker__navigation:last-of-type:hover {
  border-right-color: transparent;
  border-left-color: var(--highlight-color-1);
}

.bootstrap-wrap .time-picker {
  justify-content: space-between;
  align-items: baseline;
}

.bootstrap-wrap .sm-view .call-time-end-select {
  margin-top: 20px;
}

.bootstrap-wrap .sm-view .react-reveal .row {
  width: 100%;
}

.bootstrap-wrap .control-arrow {
  display: none;
}

.bootstrap-wrap .carousel-root {
  width: 100%;
}

.bootstrap-wrap .carousel-root .carousel-slider {
  height: 100%;
}

.bootstrap-wrap .carousel-root .carousel-slider .slide {
  background-color: transparent;
}

.bootstrap-wrap .carousel-root .carousel-slider .control-dots {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 20px;
  flex-wrap: wrap;
  height: 40px;
}

.bootstrap-wrap .carousel-root .carousel-slider .dot {
  display: flex !important;
  background-color: var(--accent-color-2) !important;
  box-shadow: none !important;
}

.bootstrap-wrap .carousel-root .carousel-slider .dot.selected {
  background-color: var(--highlight-color-1) !important;
}

.bootstrap-wrap .carousel-root .carousel-slider .carousel-status {
  display: none;
}

.bootstrap-wrap svg {
  pointer-events: none;
}

/*body {*/
/*    min-height: 100vh;*/
/*}*/
